// Loading.js
import React, { useEffect } from 'react';

const Loading = () => {
  useEffect(() => {
    // Function to create smooth and scattered movement effect for pixels
    const movePixels = () => {
      const pixels = document.querySelectorAll('.random-pixel');
      pixels.forEach(pixel => {
        const randomX = Math.random() * window.innerWidth;
        const randomY = Math.random() * window.innerHeight;
        pixel.style.transform = `translate(${randomX}px, ${randomY}px)`;
        pixel.style.transition = 'transform 2s ease-in-out';
      });
    };

    // Call movePixels initially and then every 2 seconds
    movePixels();
    const interval = setInterval(movePixels, 2000);

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col items-center justify-center h-screen bg-gray-100 overflow-hidden">
      {/* Randomly moving pixels */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        {[...Array(50)].map((_, index) => (
          <div
            key={index}
            className="random-pixel absolute w-1 h-1 bg-blue-500 rounded-full"
          />
        ))}
      </div>
      
      {/* Loading animation with progress bar */}
      <div className="flex flex-col items-center justify-center z-10">
        <div className="w-24 h-24 rounded-full border-8 border-blue-500 animate-pulse mb-8"></div>
        <div className="w-full bg-gray-300 h-3 rounded-full overflow-hidden">
          <div className="bg-blue-500 h-full animate-progress"></div>
        </div>
        <span className="mt-8 text-3xl font-bold text-gray-800">Loading...</span>
        <p className="mt-2 text-lg text-gray-600">Please wait while we load your content.</p>
      </div>
    </div>
  );
};

export default Loading;
