// NewPage.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import AppCard from '../cards/appcard';

export const NewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const items = location.state?.items || [];

  return (
    <div>
      <button
        className="flex items-center text-blue-500 mb-4 pt-4 ps-4 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
        <div className="text-lg font-bold">Back</div>
      </button>
      
      <div className="flex flex-col items-center w-full h-screen overflow-y-auto p-3">
        {items.map((item, index) => (
          <div key={index} className="flex-grow w-full mb-4">
            <AppCard
              item={item}
            />
          </div>
        ))}
      </div>
      <div className="pt-9"></div>
    </div>
  );
};

export default NewPage;
