// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Body } from './components/body';
import { NewPage } from './components/body/pages/newapps';
import AppInfo from './components/body/pages/appinfo';
import ReactGA from 'react-ga';
import Loading from './components/Loading'; 

const TRACKING_ID = "G-0Y9NK7PFK4"; 
ReactGA.initialize(TRACKING_ID);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false); 
    }, 2000); 
  }, []);

  return (
    <Router>
      <div className="App">
        {loading ? (
          <Loading /> 
        ) : (
          <Routes>
            <Route path="/" element={<Body />} />
            <Route path="/app" element={<NewPage />} />
            <Route path="/appinfo" element={<AppInfo />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
