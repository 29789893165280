import { useState, useEffect, useRef } from 'react';
import AppCard from './cards/appcard';

export const Search = ({ items }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        // Set filtered items to empty array initially
        setFilteredItems([]);
        // // Focus the input field on component mount
        // if (inputRef.current) {
        //     inputRef.current.focus();
        // }
    }, []);

    // Function to handle search input change
    const handleSearchInputChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query.length >= 3) {
            filterItems(query);
        } else {
            setFilteredItems([]); // Reset to empty array if query length is less than 3
        }
    };

    // Function to filter items based on search query
    const filterItems = (query) => {
        const filtere = items.filter(item =>
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            item.description.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredItems(filtere);
    };

    return (
        <div>
            <label className="input input-bordered flex items-center gap-2">
                <input
                    type="text"
                    className="grow"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    ref={inputRef} // Assign the ref to the input field
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="w-4 h-4 opacity-70"
                >
                    <path
                        fillRule="evenodd"
                        d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                        clipRule="evenodd"
                    />
                </svg>
            </label>

            <div>
                {filteredItems.map((item, index) => (
                    <div className='pt-6' key={index}>
                        <AppCard item={item} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Search;
