import React, { useEffect, useState } from 'react';
import { Search } from './body/search';
import { Button } from './body/button';
import { Recents } from './body/recents';
import { NewSection } from './body/newsection';
//import { Header } from './header';


export const Body = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true); // State to manage loading state

    
    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetch('https://muskmodder.live/items');
                const data = await response.json();
                setItems(data);
                setLoading(false); // Set loading to false once data is fetched
            } catch (error) {
                console.error('Error fetching items:', error);
                setLoading(false); // Ensure loading state is updated on error too
            }
        };

        fetchItems();
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="spinner-border text-blue-500" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className='p-4'>
            <Search items={items} />
            {/* <Header /> */}
            <Button />
            <Recents items={items} />
            <NewSection items={items} />
            {/* <TelegramJoin link="https://t.me/earnwithmusk" icon="🚀" /> */}
        
        </div>
    );
};
