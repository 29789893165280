import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


const Open = ({ link }) => {
    return (
        <div className='pb-10 pt-2 text-center'>
            <a
                href={link}
                rel="noopener noreferrer"
                className="block w-full max-w-md mx-auto px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors shadow-md"
                style={{ boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)" }}
            >
                Join Now
            </a>
        </div>
    );
};


const AppInfo = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const item = location.state?.item || [];

    const screenshots = Array.isArray(item.screenshots) ? item.screenshots : [];
    const OpenLink = () => {
        window.open(item.link, "_self");
    };
    const navigateBack = () => {
        navigate(-1);
    };
    return (
        <div>
            <button
                className="flex items-center text-blue-500 mb-4 pt-4 ps-4 cursor-pointer"
                onClick={navigateBack}
            >
                <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                <div className="text-lg font-bold">Back</div>
            </button>

            <div className="flex justify-center items-start min-h-screen pt-1">

                <div className="max-w-screen-lg w-full shadow-lg rounded-lg overflow-hidden">
                    <div className="p-4">
                        {/* Header section */}
                        <div className="flex items-center mb-4">
                            <div className="flex-shrink-0 rounded-full overflow-hidden h-20 w-20 flex items-center justify-center">
                                {item.logo ? (
                                    <img src={item.logo} alt="App Logo" className="h-full w-full object-cover" />
                                ) : (
                                    <svg className="h-8 w-8 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 15v4a2 2 0 01-2 2H7a2 2 0 01-2-2v-4m14-9a2 2 0 01-2 2H7a2 2 0 01-2-2m14 0v-2a2 2 0 00-2-2H9a2 2 0 00-2 2v2m12 0h3a2 2 0 012 2v2a2 2 0 01-2 2h-3m-4-4h-8" />
                                    </svg>
                                )}
                            </div>
                            <div className="ml-4">
                                <h2 className="text-2xl md:text-3xl font-bold">{item.title}</h2>
                                <p className="text-sm">{item.description}</p>
                                <div className="flex mt-2">
                                    <button className="px-3 py-1.5 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                        onClick={OpenLink}>
                                        Open
                                    </button>
                                </div>

                            </div>
                        </div>

                        {/* Category and Version section */}
                        <div className="flex justify-between pt-3">
                            <div className="px-3 py-1 bg-blue-200 text-blue-800 rounded"><span>Category : </span>{item.category}</div>
                            <div className="px-3 py-1 bg-green-200 text-green-800 rounded"><span>Version : </span>{item.version}</div>
                        </div>

                        {/* Screenshots section */}
                        <div className="mb-4 pt-3">
                            <h3 className="text-lg font-semibold mb-2">Screenshots</h3>
                            {screenshots.length > 0 ? (
                                <div className="flex overflow-x-auto space-x-2">
                                    {screenshots.map((screenshot, index) => (
                                        <div key={index} className="flex-none">
                                            <img
                                                src={screenshot}
                                                alt={`Screenshot ${index + 1}`}
                                                className="h-60 w-auto max-w-full rounded-lg shadow-md object-contain"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="px-3 py-1 bg-blue-200 text-blue-800 rounded">No screenshots available</div>
                            )}
                        </div>



                        {/* Full Description */}
                        <div className="mb-4 pt-3">
                            <h3 className="text-lg font-semibold mb-2">Description</h3>
                            <div className="px-3 py-1 bg-blue-200 text-blue-800 rounded" dangerouslySetInnerHTML={{ __html: item.fulldescription ? item.fulldescription : "No Description available" }}></div>
                        </div>


                        {/* Combo Image */}
                        {item.combo && (
                            <div className="mb-4 pt-3">
                                <h3 className="text-lg font-semibold mb-2">Today's Combo</h3>
                                <img
                                    src={item.combo}
                                    alt="App Logo"
                                    className="h-max w-auto max-w-full object-contain"
                                />
                            </div>
                        )}


                        {/* Open button*/}
                        {
                            item.link && (
                                <div className="mb-4 pt-3">
                                    <Open link={item.link} />
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>

        </div>

    );
};

export default AppInfo;
