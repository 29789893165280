import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
};

export const AppCard = ({ item }) => {
    const navigate = useNavigate();

    const handleSeeAllClick = () => {
        navigate('/appinfo', { state: { item } });
    };

    const [maxTitleLength, setMaxTitleLength] = useState(15);
    const [maxDescriptionLength, setMaxDescriptionLength] = useState(15);

    useEffect(() => {
        const parentWidth = document.querySelector('.max-w-lg').offsetWidth;
        const averageCharacterWidth = 8;
        const titleMaxLength = Math.floor(parentWidth / averageCharacterWidth) - 5;
        const descriptionMaxLength = Math.floor(parentWidth / averageCharacterWidth);

        setMaxTitleLength(titleMaxLength);
        setMaxDescriptionLength(descriptionMaxLength);
    }, []);

    return (
        <div className="flex items-center justify-between p-4 rounded-lg shadow-lg w-full max-w-lg overflow-hidden sm:flex-wrap md:flex-no-wrap">
            <div className="flex items-center"  onClick={handleSeeAllClick}>
                <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center">
                    <img src={item.logo} alt="Icon" className="w-full h-full object-cover" />
                </div>

                <div className="ml-4 flex-grow">
                    <div className="flex items-center">
                        <div style={{ maxWidth: `${maxTitleLength * 8}px`, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <h2 className="font-semibold text-lg truncate">{truncateText(item.title, maxTitleLength)}</h2>
                        </div>
                        {item.new && (
                            <span className="ml-2 px-2 py-1 text-xs bg-blue-500 rounded-full">
                                NEW
                            </span>
                        )}

                    </div>
                    <div style={{ maxWidth: `${maxDescriptionLength * 8}px`, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <p className="truncate">{truncateText(item.description, maxDescriptionLength)}</p>
                    </div>
                </div>
            </div>
            <button className="btn btn-sm ml-4" onClick={handleSeeAllClick}>Open</button>
        </div>
    );
};

export default AppCard;
