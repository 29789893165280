import React, { useState } from 'react';
import AppCard from './cards/appcard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export const NewSection = ({ items }) => {
  const navigate = useNavigate();
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleSeeAllClick = () => {
    navigate('/app', { state: { items } });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(items.length / itemsPerPage)));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedItems = items.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  return (
    <div className="flex flex-col items-start w-full pt-5">
      <div className="flex justify-between w-full mb-4">
        <div className="text-lg font-bold">New</div>
        <span
          className="ml-2 px-2 py-1 text-sm cursor-pointer"
          onClick={handleSeeAllClick}
        >
          See All
        </span>
      </div>
      {selectedItems.map((item, index) => (
        <AppCard key={startIndex + index} item={item}/>
      ))}
      <div className="flex justify-center items-center w-full mt-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`px-3 py-2 text-sm cursor-pointer ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className="mx-4 text-sm">
          Page {currentPage} of {totalPages}
        </div>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-3 py-2 text-sm cursor-pointer ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};
