import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Recents = ({ items }) => {

  
  const navigate = useNavigate();

  const handleClick = ({item}) => {
      navigate('/appinfo', { state: { item } });
  };

  return (
    <div className="flex flex-col items-start w-full">
      <div className="text-lg font-bold mb-4">Editors Choice</div>

      <div className="flex space-x-4 w-full overflow-x-auto" style={{ '-ms-overflow-style': 'none', 'scrollbar-width': 'none' }}>
        {items.map((item, index) => (
          item.trusted ? (
            <div className="flex-shrink-0 text-center" key={index} onClick={() => handleClick({item})}>
              <div className="rounded-full overflow-hidden w-14 h-14">
                <img className="w-full h-full object-cover" src={item.logo} alt={item.title} />
              </div>
              <div className="text-sm truncate w-14">{item.title}</div>
            </div>
          ) : null
        ))}
      </div>

      <div className="text-lg font-bold mb-4 pt-3">Trending Now</div>

      <div className="flex space-x-4 w-full overflow-x-auto" style={{ '-ms-overflow-style': 'none', 'scrollbar-width': 'none' }}>
        {items.map((item, index) => (
          item.trending ? (
            <div className="flex-shrink-0 text-center" key={index} onClick={() => handleClick({item})}>
              <div className="rounded-full overflow-hidden w-14 h-14">
                <img className="w-full h-full object-cover" src={item.logo} alt={item.title} />
              </div>
              <div className="text-sm truncate w-14">{item.title}</div>
            </div>
          ) : null
        ))}
      </div>
    </div>
  );
};

export default Recents;
